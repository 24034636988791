
/***
 *                                                     
 *      ,---.            ,--.  ,--.                    
 *     /  O  \ ,--.,--.,-'  '-.|  ,---.  ,---. ,--.--. 
 *    |  .-.  ||  ||  |'-.  .-'|  .-.  || .-. ||  .--' 
 *    |  | |  |'  ''  '  |  |  |  | |  |' '-' '|  |    
 *    `--' `--' `----'   `--'  `--' `--' `---' `--'    
 *                                                     
 */

.author-container {
    
    position: sticky;
    top: 0px;
    padding-bottom: 10px;
    border-top: 10px solid $brand-color;
    
    .title {
        margin: 0.25em;
    }
}

.author {
    img {
        cursor: pointer;
    }
}
