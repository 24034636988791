/***
 *                                                                                             
 *     ,-----.                 ,--.                  ,--.      ,------.                        
 *    '  .--./ ,---. ,--,--, ,-'  '-. ,--,--. ,---.,-'  '-.    |  .---',---. ,--.--.,--,--,--. 
 *    |  |    | .-. ||      \'-.  .-'' ,-.  || .--''-.  .-'    |  `--,| .-. ||  .--'|        | 
 *    '  '--'\' '-' '|  ||  |  |  |  \ '-'  |\ `--.  |  |      |  |`  ' '-' '|  |   |  |  |  | 
 *     `-----' `---' `--''--'  `--'   `--`--' `---'  `--'      `--'    `---' `--'   `--`--`--' 
 *                                                                                             
 */

form.wj-contact {
    padding: 2em;
    margin: 1em 0;
    @include media-query($on-palm) {
        padding: 0;
    }
    
    input[type="text"], input[type="email"], textarea[type="text"] {
        width: 100%;
        font-family: inherit;
        vertical-align: middle;
        margin-bottom: 1em;
        padding: 0.5em;
        border: none;
        outline: none;
        transition: box-shadow .2s ease;
    }
    
    input[type="submit"] {
        outline: none;
        color: white;
        border: none;
        /* padding: 8px 25px; */
        background-color: $brand-color;
        margin: 0.25em 0 0 0;
        cursor: pointer;
    }  
}
