/***
 *
 *     ,-----.                ,--.                               ,--.           ,--.
 *    '  .--./,--.,--. ,---.,-'  '-. ,---. ,--,--,--.     ,---.,-'  '-.,--. ,--.|  | ,---.
 *    |  |    |  ||  |(  .-''-.  .-'| .-. ||        |    (  .-''-.  .-' \  '  / |  || .-. :
 *    '  '--'\'  ''  '.-'  `) |  |  ' '-' '|  |  |  |    .-'  `) |  |    \   '  |  |\   --.
 *     `-----' `----' `----'  `--'   `---' `--`--`--'    `----'  `--'  .-'  /   `--' `----'
 *                                                                     `---'
 */

* {
  /*  border: 1px dashed #eee; */
}

a.nostyle {
  text-decoration: none;
  color: $color;
  &:hover {
    text-decoration: none;
  }
}

.btn,
[class*="btn-"] {
  font-weight: lighter !important;
  border-radius: 2px !important;
  font-weight: 300 !important;
  color: $brand-color;
  padding: 5px 15px !important;
  cursor: pointer;
  &:hover {
    color: #fff !important;
    background-color: $brand-color !important;
  }
}

.btn-default {
  border: 1px solid $brand-color;
  background-color: #fff;
}

.btn-primary,
.btn-success,
.btn-warning,
.btn-danger {
  border: 0;
  color: #eee;
}

.wrapper {
  background: #fff;
  margin-top: 5%;
  margin-bottom: 5%;
  @include media-query($on-palm) {
    margin: 0;
  }
}

.container-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(auto, 100px);
  grid-gap: 10px;
  max-width: 1200px;
  margin: 0 auto;
  @include media-query($on-palm) {
    display: block;
  }
}

.sidebar {
  grid-column: 1 / 4;
  margin-bottom: 10px;
  @include media-query($on-laptop) {
    grid-column: 1 / 5;
  }
  @include media-query($on-palm) {
    margin-bottom: 0;
  }
}

.main {
  grid-column: 4 / 13;
  border-top: 10px solid $brand-color;
  @include media-query($on-laptop) {
    grid-column: 5 / 13;
  }
}

.borders {
  border: 1px solid #eee;
}

.cards {
  border-radius: 2px;
  // border: 1px solid #ddd;
  &:hover {
    -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  }
}

.hide {
  display: none;
}

.pd {
  padding: 2em 1em;
  @include media-query($on-palm) {
    padding: 1em 0.2em;
  }
}

.pds {
  padding: 1em;
  @include media-query($on-palm) {
    padding: 1em;
  }
}

.white {
  color: #eee !important;
  a {
    color: #eee !important;
  }
}

.categories-page {
  p {
    font-size: $small-fs;
    padding: 0.5em;
    margin: 0;
  }
  .col-md-4 {
    margin-bottom: 1em;
  }
  .image {
    height: 150px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .cat-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @include media-query($on-palm) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
  }
}

/* Used for animation by viewport checker */
.hidden {
  opacity: 0;
}
.visible {
  opacity: 1;
}
.meta {
  color: $grey-color;
}

/* Responsive videos */
.video {
  width: 100% !important;
  height: 515px !important;
  margin-bottom: 1em;
  @include media-query($on-palm) {
    height: 250px !important;
  }
}

/* Hide element until the user scrolls till bottom */
.disqus-placeholder.is-hidden {
  display: none;
}

.text-center {
  text-align: center;
}

.m0 {
  margin: 0;
}

.m0a {
  margin: 1em auto;
}

.p0 {
  padding: 0;
}

.dashed {
  border: none;
  border-bottom: 1px dashed #ddd;
}

.shadow {
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
}
.social {
  ul.portfolio {
    list-style: none;
    li {
      display: inline-block;
      /* font-size: $small-fs; */
      + li:before {
        content: "| ";
      }

      a.active {
        color: $color;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  ul.sm {
    margin-top: 1.5em;
    width: 70%;
    list-style: none;
    display: flex;
    justify-content: space-around;
    i {
      font-size: $base-fs * 1.1;
      border: 1px solid;
      padding: 10px;
      border-radius: 50%;
      color: white;
      background: $brand-color;
      &:hover {
        background: $color;
      }
    }
  }
}

.main-container {
  padding: 25px 30px;
  hr {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.blog {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 10px;
  margin-bottom: 1.25em;
  @include media-query($on-palm) {
    display: block;
  }
  .image {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all 200ms ease-in;
    @include media-query($on-palm) {
      height: 200px;
    }
  }

  .content {
    padding: 10px;
  }
}

.projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  @include media-query($on-laptop) {
    grid-template-columns: repeat(1, 1fr);
  }

  .project {
    margin-bottom: 1em;
  }
  .project-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
    width: 100%;
    border-bottom: 1px solid #eee;
  }

  .title {
    margin: 10px;
  }
}

.work {
  margin-bottom: 30px;
  .work-ind {
    padding-left: 0;
    @include media-query($on-palm) {
      padding-right: 0;
    }
  }
  .work-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 300px;
  }

  .details {
    padding: 10px;
  }
  .company,
  .date {
    margin-bottom: 0;
  }
}

.title-space {
  display: grid;
  grid-template-columns: 8fr 4fr;
  align-items: center;
  @include media-query($on-palm) {
    grid-template-columns: 12fr;
  }

  .form-control:focus {
    box-shadow: none;
    border-color: $brand-color;
  }

  .input-group-text {
    color: white;
    background-color: $brand-color;
    border: 1px solid $brand-color;
  }
}

.text-right {
  text-align: right;
}

.copy {
  color: $grey-color;
}
