/***
 *
 *    ,--.           ,--.,--.          ,--.   ,--.                ,--.    ,------.                ,--.
 *    |  |,--,--,  ,-|  |`--',--.  ,--.`--' ,-|  |,--.,--. ,--,--.|  |    |  .--. ' ,---.  ,---.,-'  '-.
 *    |  ||      \' .-. |,--. \  `'  / ,--.' .-. ||  ||  |' ,-.  ||  |    |  '--' || .-. |(  .-''-.  .-'
 *    |  ||  ||  |\ `-' ||  |  \    /  |  |\ `-' |'  ''  '\ '-'  ||  |    |  | --' ' '-' '.-'  `) |  |
 *    `--'`--''--' `---' `--'   `--'   `--' `---'  `----'  `--`--'`--'    `--'      `---' `----'  `--'
 *
 */
.header-image {
    height: 400px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.post-title {
    font-weight: bold;
    line-height: 1.8em;
    font-size: 2.2em;
        @include media-query($on-palm) {
            line-height: 1.5em;
            font-size: 1.6em;
        }

}
.blog-content, .page-container {
    article {

        img, iframe {
            margin: 1em 0;
        }

        > p {
             text-align: justify;
        }

        p {



            a:not(.btn) {
                text-decoration: none;
                background: -webkit-linear-gradient(transparent 90%, $brand-color 10%);
                box-shadow: inset 0 0 0 0 $brand-color;
                -webkit-transition: box-shadow ease 500ms;
                -moz-transition: box-shadow ease 500ms;
                transition: box-shadow ease 500ms;
                padding: 1px 2px;
                &:hover {
                    color: #fff;
                    box-shadow: inset 0 -100px 0 0 $brand-color;
                }
            }
         }
    }
}



.categories {margin: 1em 0;
    span {
        a {
            background-color: $brand-color;
            font-size: $small-fs;
            color: #fff;
            padding: 2px 10px;
            border-radius: 2px;
            &:hover {
                background-color: darken($brand-color, 5%);
            }
        }
    }
}

.disqus {
    margin: 2em 0;
    iframe {
        max-height: 450px !important;
    }
}

.post-container, .page-container, .blog-content {
    max-width: 960px;
    margin: 0 auto;
}

blockquote {
    border-left: 5px solid $brand-color;
    padding-left: 1em;
    margin-left: 1em;
    font-style: italic;
    font-size: 1.4em;
    @include media-query($on-palm) {
        font-size: 1.5em;
        padding-left: 0.5em;
        margin-left: 0.5em;
    }
}
.featured-image {
    background-size: cover;
    width: 100%;
    height: 450px;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 1.5em;
    @include media-query($on-palm) {
        height: 200px;
    }
}


.comment-loader {
    text-align: center;
    .comments {
        border: none;
        padding: 5px 25px;
        background: $brand-color;
        color: white;
        cursor: pointer;
    }
}
