/***
 *                                                                           
 *    ,-----.                             ,---.   ,--.           ,--.        
 *    |  |) /_  ,--,--. ,---.  ,---.     '   .-',-'  '-.,--. ,--.|  | ,---.  
 *    |  .-.  \' ,-.  |(  .-' | .-. :    `.  `-.'-.  .-' \  '  / |  || .-. : 
 *    |  '--' /\ '-'  |.-'  `)\   --.    .-'    | |  |    \   '  |  |\   --. 
 *    `------'  `--`--'`----'  `----'    `-----'  `--'  .-'  /   `--' `----' 
 *                                                      `---'                
 */
html {
  scroll-behavior: smooth;
}

body {
  background: #fefefe;
  font-family: $base-ff;
  font-size: $base-fs;
  font-weight: $base-fw;
  color: $color;
  line-height: $base-lh;
  text-rendering: optimizeLegibility;
  height: 100%;
  @include media-query($on-palm) {
    margin: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $head-ff;
  font-weight: normal;
  line-height: $base-lh;
}

h1 {
  font-size: 1.7em;
  @include media-query($on-palm) {
    font-size: 1.4em;
  }
}

h2 {
  font-size: 1.4em;
  @include media-query($on-palm) {
    font-size: 1.2em;
  }
}

h3 {
  font-size: 1.2em;
  @include media-query($on-palm) {
    font-size: 1.1em;
  }
}

h4,
h5,
h6 {
  font-size: 1.1em;
  @include media-query($on-palm) {
    font-size: 1em;
  }
}

p {
  @include media-query($on-palm) {
    font-size: 18px;
  }
}

a {
  color: $brand-color;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: $color;
    text-decoration: none !important;
  }
}

img {
  max-width: 100%;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: $small-fs;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: $small-fs;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-size: $small-fs;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-size: $small-fs;
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: $brand-color;
}

::selection {
  color: white;
  background: $brand-color;
}
